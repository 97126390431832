/* eslint-disable @typescript-eslint/no-unused-vars */
import { globalHistory, HistoryLocation } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby'
import React, { ReactNode, ReactElement, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import useGlobalState from '../hooks/useGlobalState'
import { api } from '../../../utils/api'
import { aplicationFormFixtures } from '@templates/PageFormStep/config'
import { isBrowser } from '@utils/functional'
import useLightbox from '../hooks/useLightbox'
import DialogExitOut from '@components/core/dialog/DialogExitOut'

export interface IFormsContext {
  registerField: (config: FormsFieldRegisterProps) => void
  unregisterField: (config: FormsFieldRegisterProps) => void
  updateField: (config: FormsFieldUpdateProps) => void
  validateStep: (stepId?: string) => FormsValidationData
  submit: (finalizeValues?: (values: FormValues) => void) => void
  submitContact: (
    finalizeValues?: (values: FormValues) => void
  ) => Promise<boolean> | false
  setCurrentStep: (value: number) => void
  fields: { [key: string]: FormsFieldProps }
  currentStep: number
  stepsComplete: number
  isFormPage: boolean
  optionsSex: MCDC.Props.IOption[]
  optionsReferalFrom: MCDC.Props.IOption[]
  optionsYesNo: MCDC.Props.IOption[]
}

export const FormsContext = React.createContext<IFormsContext>({
  registerField: () => console.log('registerField'),
  unregisterField: () => console.log('unregisterField'),
  updateField: () => console.log('updateField'),
  validateStep: () => ({ isSuccess: false, errors: [] }),
  submit: () => console.log('submit'),
  submitContact: () => false,
  setCurrentStep: () => console.log('setCurrentStep'),
  fields: {},
  currentStep: 1,
  stepsComplete: 0,
  isFormPage: false,
  optionsSex: [],
  optionsReferalFrom: [],
  optionsYesNo: [],
})

export type FormsFieldProps = {
  id: string
  stepId?: string
  key: string
  value: any
  isError?: boolean
  isToggled?: boolean
  isPristine?: boolean
  isRequired?: boolean
  errorCode?: string
}

export type FormsFieldRegisterProps = {
  id: string
  stepId?: string
  key: string
  isRequired?: boolean
  value?: unknown
}

export type FormsFieldUpdateProps = {
  id: string
  stepId?: string
  value: unknown
  isError?: boolean
  isToggled?: boolean
  isRequired?: boolean
}

export type FormsValidationData = {
  stepId?: string
  errors: FormsFieldProps[]
  isSuccess: boolean
}

type FormValues = { [key: string]: { [key: string]: any } | undefined }

export type FormsProviderProps = {
  children: ReactNode
  location?: HistoryLocation
  formStepPages: MCDC.Contentful.IPageFormStep[]
  formSuccessPage: MCDC.Contentful.IPageGeneric
}

const globalProps: {
  isTransitioning: boolean
  isExiting: boolean
  currentLocation?: HistoryLocation
  isFormPage: boolean
} = {
  isTransitioning: false,
  isExiting: false,
  currentLocation: undefined,
  isFormPage: false,
}

export default function FormsProvider({
  children,
  formStepPages = [],
  location: pageLocation,
  formSuccessPage,
}: FormsProviderProps): ReactElement {
  const intl = useIntl()
  const queryParams = isBrowser && new URLSearchParams(window.location.search)
  const isDebug = queryParams && queryParams.get('debug') === 'true'
  const { location } = useGlobalState()
  const { showDialog } = useLightbox()
  const [fields, setFields] = useState<{ [key: string]: FormsFieldProps }>({})
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [stepsComplete, setStepsComplete] = useState<number>(isDebug ? 4 : 1)
  const [isFormPage, setIsFormPage] = useState<boolean>(false)
  const [values, setValues] = useState<FormValues>(
    isDebug ? (aplicationFormFixtures as any) : {}
  )

  const [optionsSex] = useState<MCDC.Props.IOption[]>([
    {
      label: intl.messages['applicationForm.sex.option1'] as string,
      value: intl.messages['applicationForm.sex.value1'] as string,
    },
    {
      label: intl.messages['applicationForm.sex.option2'] as string,
      value: intl.messages['applicationForm.sex.value2'] as string,
    },
    {
      label: intl.messages['applicationForm.sex.option3'] as string,
      value: intl.messages['applicationForm.sex.value3'] as string,
    },
  ])

  const [optionsReferalFrom] = useState<MCDC.Props.IOption[]>([
    {
      label: intl.messages['applicationForm.referalFrom.option1'] as string,
      value: intl.messages['applicationForm.referalFrom.option1'] as string,
    },
    {
      label: intl.messages['applicationForm.referalFrom.option2'] as string,
      value: intl.messages['applicationForm.referalFrom.option2'] as string,
    },
    {
      label: intl.messages['applicationForm.referalFrom.option3'] as string,
      value: intl.messages['applicationForm.referalFrom.option3'] as string,
    },
    {
      label: intl.messages['applicationForm.referalFrom.option4'] as string,
      value: intl.messages['applicationForm.referalFrom.option4'] as string,
    },
    {
      label: intl.messages['applicationForm.referalFrom.option5'] as string,
      value: intl.messages['applicationForm.referalFrom.option5'] as string,
    },
  ])

  const [optionsYesNo] = useState<MCDC.Props.IOption[]>([
    {
      label: intl.messages['label.yes'] as string,
      value: true,
    },
    {
      label: intl.messages['label.no'] as string,
      value: false,
    },
  ])

  function getFieldValue(
    key: string,
    stepId?: string,
    field?: FormsFieldProps
  ) {
    if (stepId) {
      const value = values[stepId]?.[key]
      return field?.value !== undefined ? field?.value : value
    }
    const value = values[key]
    return field?.value !== undefined ? field?.value : value
  }

  function setFieldValue(key: string, stepId?: string, value?: any) {
    if (stepId && values[stepId] === undefined) values[stepId] = {}

    if (stepId) {
      const castedValue = values as any
      castedValue[stepId][key] = value
      return
    }
    values[key] = value
  }

  function registerField({
    id,
    key,
    stepId,
    isRequired,
    value,
  }: FormsFieldRegisterProps) {
    setFields((prev) => {
      const field = prev[id]
      const currentValue = getFieldValue(key, stepId, field)
      const initialValue = currentValue !== undefined ? currentValue : value
      setFieldValue(key, stepId, initialValue)

      return {
        ...prev,
        [id]: {
          ...(field || {}),
          id,
          stepId,
          key,
          value: initialValue,
          isError: field?.isError || false,
          isToggled: field?.isToggled || false,
          isPristine: field?.isPristine === false ? false : true,
          isRequired,
        },
      }
    })
  }

  function unregisterField({ id, key, stepId }: FormsFieldRegisterProps) {
    if (globalProps.isTransitioning) return
    setFields((prev) => {
      const field = prev[id]
      setFieldValue(field.key, field.stepId, undefined)
      if (field) delete prev[id]
      return prev
    })
  }

  function updateField({
    id,
    value,
    isError = false,
    isToggled = false,
    isRequired,
  }: FormsFieldUpdateProps) {
    setFields((prev) => {
      const field = prev[id]
      if (!field) return prev
      setFieldValue(field.key, field.stepId, value)
      return {
        ...prev,
        [id]: {
          ...field,
          id,
          value,
          isError: (isRequired || field.isRequired) && isError,
          isToggled,
          isPristine: false,
          isRequired: isRequired !== undefined ? isRequired : field.isRequired,
          errorCode: undefined,
        },
      }
    })
  }

  function resetFields() {
    setFields((prev) =>
      Object.entries(prev).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [key]: { ...value, value: undefined },
        }),
        {}
      )
    )
    setValues(() => ({}))
  }

  function validateStep(stepId?: string): FormsValidationData {
    let isValid = true
    const validatedFields: { [key: string]: FormsFieldProps } = Object.entries(
      fields
    )
      .filter(
        ([key, value]) =>
          value.isRequired && (!stepId || stepId === value.stepId)
      )
      .reduce((obj, [key, entry]) => {
        const value = getFieldValue(entry.key, entry.stepId)
        const isError =
          (!value && value !== false) ||
          (Array.isArray(value) &&
            (value.length === 0 || value.some((entry) => !!!entry))) ||
          (Array.isArray(value) &&
            typeof value[0] === 'object' &&
            value.some((entry) => {
              let isError = false
              Object.values(entry).forEach((value) => {
                if (value === undefined) isError = true
              })
              return isError
            })) ||
          (Array.isArray(value) &&
            typeof value[0] === 'string' &&
            value.some((entry) => {
              let isError = false
              Object.values(entry).forEach((value) => {
                if (!value) isError = true
              })
              return isError
            }))

        if (isError) {
          isValid = false
        }

        return {
          ...obj,
          [key]: {
            ...entry,
            isError,
            errorCode: 'validation.required',
          },
        }
      }, {})

    const erroredFields: FormsFieldProps[] = Object.values(
      validatedFields
    ).filter((entry: FormsFieldProps) => entry.isError)

    setFields((prev) => ({
      ...prev,
      ...validatedFields,
    }))

    return { stepId, isSuccess: isValid, errors: erroredFields }
  }

  async function submit(finalizeValues?: (values: FormValues) => void) {
    const isFinalStep = currentStep >= formStepPages.length
    const stepConfig = formStepPages.find(
      (entry) => parseInt(entry.step, 10) === currentStep
    )

    if (!stepConfig) return

    const validation = validateStep(stepConfig?.key)
    if (validation && !validation.isSuccess) {
      const firstErrorField = validation.errors[0]
      if (stepConfig.key === firstErrorField.stepId) {
        const el = document.getElementById(firstErrorField.id)
        if (el) {
          window.scrollTo({
            behavior: 'smooth',
            top:
              el.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              200,
          })
        }
      } else {
        const nextStep = formStepPages.find(
          (entry) => entry.key === firstErrorField.stepId
        )
        if (nextStep) {
          navigate(`${nextStep.fields.linkTo.url}#${firstErrorField.id}`)
        }
      }
      return
    }

    if (!isFinalStep) {
      const nextStep = formStepPages.find(
        (entry) => parseInt(entry.step, 10) === currentStep + 1
      )
      if (nextStep) {
        navigate(nextStep.fields.linkTo.url)
      }
      return
    }

    const finalizedValues = finalizeValues ? finalizeValues(values) : values
    const response = await api.submit(finalizedValues as any)

    if (response?.status === 400) {
      const errors: { field: string; message: string }[] =
        (response.data as any).violations || []
      const validatedFields: { [key: string]: FormsFieldProps } = errors.reduce(
        (obj, error) => {
          const fieldSplit = error.field.split('.')
          const fieldkey = fieldSplit.length > 1 ? fieldSplit[1] : fieldSplit[0]
          const field = Object.values(fields).find(
            (entry) => entry.key && entry.key === fieldkey
          )
          if (!field) return obj
          return {
            ...obj,
            [field.id]: {
              ...field,
              isError: true,
              errorCode: `validation.${error.message}`,
            },
          }
        },
        {}
      )

      setFields((prev) => ({
        ...prev,
        ...validatedFields,
        freindlyCapture: {
          ...prev.freindlyCapture,
          value: undefined,
        },
      }))

      const firstErrorField = Object.values(validatedFields)[0]
      if (firstErrorField) {
        const nextStep = formStepPages.find(
          (entry) => entry.key === firstErrorField.stepId
        )
        if (nextStep) {
          navigate(`${nextStep.fields.linkTo.url}/#${firstErrorField.id}`)
        }
      }
    }

    if (!response || response?.status >= 300) return
    globalProps.isExiting = true
    navigate(formSuccessPage.fields.linkTo.url)
  }

  async function submitContact(
    finalizeValues?: (values: FormValues) => void
  ): Promise<boolean> {
    const validation = validateStep()
    if (validation && !validation.isSuccess) {
      const firstErrorField = validation.errors[0]
      const el = document.getElementById(firstErrorField.id)
      if (el) {
        window.scrollTo({
          behavior: 'smooth',
          top:
            el.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            200,
        })
      }
      return false
    }

    const finalizedValues = finalizeValues ? finalizeValues(values) : values
    const response = await api.submitContact(finalizedValues as any)

    if (response?.status === 400) {
      const errors: { field: string; message: string }[] =
        (response.data as any).violations || []

      const validatedFields: { [key: string]: FormsFieldProps } = errors.reduce(
        (obj, error) => {
          const fieldSplit = error.field.split('.')
          const fieldkey = fieldSplit.length > 1 ? fieldSplit[1] : fieldSplit[0]
          const field = Object.values(fields).find(
            (entry) => entry.key && entry.key === fieldkey
          )
          if (!field) return obj
          return {
            ...obj,
            [field.id]: {
              ...field,
              isError: true,
              errorCode: `validation.${error.message}`,
            },
          }
        },
        {}
      )
      setFields((prev) => ({
        ...prev,
        ...validatedFields,
        freindlyCapture: {
          ...prev.freindlyCapture,
          value: undefined,
        },
      }))
    }

    if (!response || response?.status >= 300) return false

    resetFields()

    return true
  }

  useEffect(() => {
    if (currentStep > stepsComplete) setStepsComplete(currentStep)
  }, [currentStep, stepsComplete])

  useEffect(() => {
    const stepConfig = formStepPages.find((entry) =>
      location?.pathname.includes(entry.fields.linkTo.url)
    )

    if (!isFormPage && stepConfig) {
      globalProps.isFormPage = true
      api.initiate()
      setIsFormPage(true)
    } else if (isFormPage && !stepConfig) {
      globalProps.isFormPage = false
      setFields({})
      setValues({})
      setCurrentStep(1)
      setStepsComplete(1)
      setIsFormPage(false)
    }

    if (stepConfig) {
      const step = parseInt(stepConfig?.step || '0', 10)
      if (!isFormPage && step > stepsComplete) {
        const firstStepConfig = formStepPages.find(
          (entry) => entry.step === '1'
        )
        navigate(firstStepConfig?.fields.linkTo.url || '#')
        return
      } else {
        setCurrentStep(step)
      }
    }
    globalProps.currentLocation = location
  }, [location])

  useEffect(() => {
    if (location?.pathname !== pageLocation?.pathname) {
      globalProps.isTransitioning = true
    } else if (location?.pathname === pageLocation?.pathname) {
      globalProps.isTransitioning = false
    }
  }, [pageLocation, location])

  useEffect(() => {
    return globalHistory.listen(async ({ action, location }) => {
      const stepConfig = formStepPages.find((entry) =>
        location?.pathname.includes(entry.fields.linkTo.url)
      )
      if (
        (action === 'PUSH' || action === 'POP') &&
        globalProps.isFormPage &&
        !stepConfig &&
        !globalProps.isExiting
      ) {
        navigate(globalProps.currentLocation?.pathname || '/', {
          replace: true,
        })
        showDialog({
          id: 'dialogExitOut',
          component: DialogExitOut,
          props: {
            onClick: () => {
              globalProps.isExiting = true
              navigate(location.pathname)
            },
          },
        })
      }
      globalProps.isExiting = false
    })
  }, [])

  return (
    <FormsContext.Provider
      value={{
        currentStep,
        stepsComplete,
        fields,
        isFormPage,
        optionsSex,
        optionsReferalFrom,
        optionsYesNo,
        setCurrentStep,
        registerField,
        unregisterField,
        updateField,
        validateStep,
        submit,
        submitContact,
      }}
    >
      {children}
    </FormsContext.Provider>
  )
}
